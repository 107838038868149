.aboutus-admin {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-transform: uppercase;
    row-gap: 10px;
}
.aboutus-admin h1{
    text-align: left;
    font-size:20px
}

.about-admin-form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}

.about-admin-form #outlined-input {
    font-weight: 100 !important;
    line-height: 1.5 !important;
    border-width: 0.1px !important;
    resize: none;
}
#btn{
    background-color:green
}
.about-admin-form>span {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
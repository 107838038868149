@media screen and (min-width:992px){
    .products{
        height: 100vh;
        margin: 0;
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        align-items: center !important;
    }
    .product-content{
        text-align:left;
    }
    .product-content .product-button-wrapper{
        display: flex;
        justify-content: flex-start !important;
        margin-top: 20px;
    }
    #basic-button{
        padding: 12px 25px !important;
        border: 2px solid var(--accent-color);
        background-color: transparent;
        width: fit-content !important;
        color: var(--secondary-color);
        margin-top: 0;
        font-weight: bold;
        letter-spacing: 2px !important;
        font-size: 15px;
        display: flex;
        align-items: center;
        column-gap: 10px;
        cursor: pointer; 
    }
    #basic-button:hover{
        background-color:var(--accent-color);
        transition: 0.3s ease;
    }
    .product-content h1{
        font-size:45px !important;
        text-align: left;
        width: 100%;
    }
    .product-content p{
        font-size:18px !important;
    }
    .product-cards{
        display: grid;
        grid-template-columns: auto auto auto;
        justify-content: center;
        align-items: center;
        row-gap: 40px;
        column-gap: 30px;
        height: auto;
    }

}


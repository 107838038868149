@media screen and (min-width:992px) {
    .about-mobile {
        display: none !important;
    }
    .about-desktop {
        display: flex !important;
        flex-direction: column !important;
        height: 100vh;
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }
    .about-tabs {
        min-height: 20%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .about-tabs span {
        width: 100%;
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        cursor: pointer;
    }
    .tab {
        text-transform: uppercase;
        font-size: 20px;
        font-weight: bolder;
        color: var(--primary-color);
        min-height: 20%;
    }
    .tab-active {
        background-color: #f1534d;
        transition: background-color 0.5s ease;
        color: var(--secondary-color);
        height: 20%
    }
    .tab-content-about {
        width: 70%;
        text-align: justify;
        align-self: center;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly !important;
        transition: display 1s ease;
        height: 80%;
    }
    .tab-content-about p {
        font-size: 18px!important;
        font-weight: 300;
        font-style: italic;
    }
    .tab-content-about h4 {
        font-size: 20px!important;
    }
    
    .cards-desktop {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        height: 100%;
        text-transform: uppercase;
    }
    .cards-desktop .cards {
        width: 70%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between !important;
        transition: display 1s ease;
        height: fit-content;
    }
    .cards-desktop .cards .card-wrapper {
        height: 370px;
        width: 270px;
    }
}
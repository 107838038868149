.BasicCard {
    max-width: 400px !important;
    border-radius: 10px;
    background: linear-gradient(145deg, #ffffff, #e6e6e6);
    box-shadow: 2px 2px 5px #d9d9d9, -2px -2px 5px #ffffff;
}

.btn-blog {
    color: var(--accent-color) !important;
    font-weight: bold !important;
    letter-spacing: 1px !important;
}

#blog-preview-body {
    width: inherit !important;
}

#blog-image {
    height: 200px !important;
    width: 100% !important;
}
@media screen and (min-width:992px) {
    .card-wrapper {
        height: fit-content !important;
        overflow: hidden;
        border-radius: 20px;
        row-gap: 10px;
        border: 0;
        padding-top: 20px;
        transition: height 1s ease;
        background: #ffffff;
        box-shadow:  17px 17px 72px #e0e0e0,
                     -17px -17px 72px #ffffff;
    }
    .card-wrapper:hover {
        transform: scale(1.1);
        transition: transform 1s ease;
    }
    .card-wrapper:hover .card-content {
        background: #e0e0e0;
    }
    .card-wrapper img {
        height: 180px;
        width: 180px;
        transform: translateY(0%);
        border-radius: 50% !important;
    }
    .card-wrapper .card-content {
        height: inherit !important;
        background-color: transparent !important;
        border-radius: 0;
        display: flex;
        color: var(--primary-color);
        align-items: center;
        row-gap: 10 !important;
        padding: 20px 0px !important;
    }
    .card-wrapper .card-content .social-media img {
        height: 25px !important;
        width: 25px;
    }
}

.hamBurger{
  display: flex;
  justify-content: flex-end;
}
#ham-link{
  text-decoration: none;
  color: var(--primary-color)
}

.navbar {
    opacity: 1;
    z-index: 1;
    font-size: 18px;
    background-color: white;
    position: fixed;
    top: 0;
    width: 100%;
    display: grid;
    grid-template-columns: auto auto;
    box-sizing: border-box;
  }
  .navbarTranslationUp{
    transform: translateY(-100%);
    transition: 0.5s ease-in-out;
  }
  .navbarTranslationDown{
    transform: translateY(0%);
  }
  .navbar .logo {
    display: grid;
    grid-template-columns: auto auto;
    font-size: 15px;
    column-gap: 10px;
    font-weight: 900;
    text-transform: uppercase;
    width: fit-content;
    justify-content: flex-start;
    align-items: center;
    /* background-color: red; */
  }
  .navbar #nav-logo{
      height: 50px;
  }

  .navbar .navList {
    display: none ;
  }
  .scroll-down{
    transform: translateY(-100%);
    transition: 0.5s ease-in-out;
  }
  .scroll-up{
    transform: translateY(0%);
  }
:root{
  --primary-color: #2A3749;
  --secondary-color: white;
  --accent-color: #F1534D;
  --side-padding: 50px;
  --top-bottom-padding: 10px;
}
html{
  scroll-behavior: smooth;
}
body{
  background-color: var(--primary-color);
  
}
*{
  padding: 0;
  margin: 0;
  /* font-family: 'Poppins', sans-serif; */
  /* font-family: 'Roboto', sans-serif; */
  font-family: 'Sora';
  /* font-family: 'Epilogue'; */
  box-sizing: border-box;
}

.App{
  overflow: visible !important;
  scroll-behavior: smooth !important;
  display: grid;
  grid-template-columns: auto;
}
.MuiDivider-middle{
  background-color: var(--accent-color);
  border: 1px rgba(255, 255, 255, 0.555) solid !important;
  border-radius: 50%;
  width: 70%;
  transform: translateX(20%)!important;
}


.container{
  padding: var(--top-bottom-padding) var(--side-padding);
}

.App-loader{
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.App-loader img{
  height: 50%;
}
.toastContainer{
  font-size:15px;
  text-transform: capitalize;
}
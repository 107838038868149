@media screen and (min-width:992px){
    .form{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        row-gap: 20px;
        width: 50%;
        padding: 30px 25px;
        box-sizing: border-box;
    }
    .form .normal-inp{
        display: grid;
        grid-template-columns: auto auto;
        row-gap: 20px;
        column-gap: 10px;
        width: 100%;
    }
    .form-radio{
        display: grid;
        grid-template-columns: auto auto auto;
        column-gap: 20px;
        row-gap: 0px !important;
    }
    .form-radio span{
        display:flex;
        column-gap:10px;
        align-items: center;
        font-size: 12px;
    }
}
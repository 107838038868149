@media screen and (min-width:992px){
    .productCard-wrapper {
        height: fit-content !important;
        width: auto;
    }
    .productCard{
        height: 200px;
        width: 200px;
        padding: 20px 40px;
    }
    .productCard p{
        font-size:15px;
      }
      .productCard img {
        height: 100px;
      }
}
.Footer{
    background-color: var(--secondary-color);
    box-sizing: border-box;
    display: grid;
    grid-template-columns: auto;
    row-gap: 20px;
}
.Footer .logo{
    display: flex;
    align-items: center;
    font-size:20px;
    font-weight: bold;
    text-transform:uppercase;
}
.Footer .logo img{
    height: 50px;
}

.Footer .footer-slogan p:nth-child(1){
    font-size:20px;
    font-weight: 500;
}
.Footer .footer-slogan p:nth-child(2){
    font-size:12px;font-style: italic;
}
.Footer .footer-info{
    display: flex;
    justify-content: space-between;
    width:100%
   
}
.Footer .footer-info h4{
    font-size:15px;
    margin-bottom:5px
}
.Footer .footer-info p{
    font-weight: 300;
    font-style: italic;
    font-size:12px;

}
.footer-info h4{
 display: flex;
 column-gap:5px
}
#whatsapp-imgae{
    height: 25px;
}
.Footer .footer-follow img{
    height: 25px;
    margin-right: 10px;
    margin-top: 10px;
}

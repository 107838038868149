.contact{
    height: fit-content;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    color: var(--secondary-color);
    text-transform: uppercase;
    row-gap: 20px;
    text-align: center;
    padding-top: 50px;
}
.contact span{
    display: flex;
    flex-direction: column;
    row-gap:10px
}
.contact p{
    font-weight: 200;
    font-size: 14px;
    font-style: italic;
    text-transform: capitalize;
    width: 100%;
    text-align: center;
}
@media screen and (min-width:992px) {
  .swiper {
    background-color: blue;
    width: 80% !important;
  }
  
  .swiper-wrapper {
    width: 100% !important;
    height: 300px !important;
  }
  .swiper-slide {
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .slide-content {
    width: 60%;
    display: flex;
    padding: 10px;
    justify-content: flex-start;
    column-gap: 20px;
    box-sizing: border-box;
  }
  .slide-content span {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
  }
  .slide-content h1 {
    font-size: 22px !important
  }
  .slide-content img {
    height: 150px;
    width: 150px;
  }
  .slide-content p {
    font-size: 15px !important;
    font-weight: 300
  }
}
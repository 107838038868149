.admin {
    color: var(--secondary-color);
   display: flex;
   flex-direction: column;
    justify-content: flex-start;
    height: 100vh;
    width: 100%;
    row-gap: 30px;
}

.admin h1 {
    text-align: center;
    text-transform: uppercase;
}

.admin-grid {
    color: var(--secondary-color);
    display: grid;
    grid-template-columns: 1fr;
    align-items: baseline;
    column-gap: 20px;
    height: 100vh;
    width: 100%;
}

@media screen and (min-width:992px) {
    .admin h1 {
        text-align: center;
        text-transform: uppercase;
    }
    .admin-grid {
        color: var(--secondary-color);
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 40px;
        height: fit-content;
        width: 100%;
        border: 1px solid var(--secondary-color);
        
       
    }
   .admin-grid span p{
       text-align: center;
       padding: 10px;
       cursor: pointer;
   }
   .active{
       background-color: var(--accent-color);
       transition: all 0.1s ease-in-out; 
   }
}
@media screen and (min-width: 992px) {
 
  .home-page .banner-front {
    height: 80% !important;
   
  }
  .home-page .banner-front .banner-content {
    font-size: 24px !important;
    z-index: 1;
    color: var(--secondary-color);
    letter-spacing: 5px;
    text-align: start !important;
    text-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    transform: translateY(45%) !important;
  }
  .banner-content p {
    letter-spacing: 5%;
    font-size: 18px !important;
    font-style: italic;
    width: 70%;
  }
  .banner-content .banner-button {
    padding: 12px 25px !important;
    border: 2px solid var(--accent-color);
    background-color: transparent;
    width: fit-content !important;
    color: var(--secondary-color);
    margin-top: 30px;
    font-weight: bold;
    letter-spacing: 2px !important;
    font-size: 15px;
    display: flex;
    align-items: center;
    column-gap: 10px;
    cursor: pointer;
  }
  
  .banner-content .banner-button:hover{
    background-color:var(--accent-color);
    transition: 0.3s ease;
}
  .banner-content .banner-button #arrow {
    font-size: 25px;
    display: flex;
    align-items: center;
  }
  .home-page .bottom-info {
    height: 20%;
    padding: 0px var(--side-padding);
    display: grid !important;
    grid-template-columns: auto auto auto;
    align-items: center;
    justify-content: space-between;
  }
  .home-page .bottom-info .info-card{
      display: flex;
      align-items: center;
      column-gap: 10px;
      cursor: pointer;
  }
  .info-card .info-content p:nth-child(1){
    font-size:20px;
    font-weight: 900;
    color: #fff;
    letter-spacing:2px;
    text-transform:uppercase;
  }
  a{
    width: 100%;
  }
  .info-card .info-content p:nth-child(2){
    font-size:12px;
    font-weight: normal;
    color: #fff;
    
    text-transform:uppercase;
  }

}

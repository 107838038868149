@media screen and (min-width: 992px) {
    .modal-wrapper {
        height:100vh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 10px;
    }
    .modal-content{
        height:100%;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        column-gap: 40px;
    }
    #modal-images {
        height: 500px;
        width: 700px;
    }
}
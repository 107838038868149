.Team-Details{
   max-height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    row-gap: 10px;
}
#panel1a-header{
    min-width: 500px;
}
.accordion-card{
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 20px;
}
#btn-team{
    cursor: pointer;
}